import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { Link } from "gatsby-plugin-react-i18next"
import Navigation from "./navigation"
import BackgroundAnimation from "./background-animation"

const Layout = ({ children,  lightBackground, noAnimation}) => {
    return (
        <React.Fragment>
        { lightBackground? '' : <Helmet bodyAttributes={{ class: "bg-home-main" }} /> }
        <div className="flex flex-col h-screen justify-between">
            <div className="container mx-auto">
                <header className="h-65">
                    <Navigation />
                </header>
                <main className="mb-auto">{children}</main>
            </div>

            { noAnimation ? '' : ( <div className="-z-10 absolute top-0 hidden lg:block"> <BackgroundAnimation /> </div>) }

            <footer className={`h-10 ${lightBackground ? "bg-home-main" : "bg-home-sub"} `}>
                <div className="container mx-auto text-center pt-2">
                    <Link to="/">Jet4Code</Link> {new Date().getFullYear()} All right Reserved
                </div>
            </footer>
        </div>
        </React.Fragment>
    )
}


Layout.propTypes = {
    children: PropTypes.node.isRequired
}

export default Layout