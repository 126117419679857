import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"


const Logo = () => {
  
  const data = useStaticQuery(graphql`query LogoQuery {
    logo: file(base: {eq: "jet4code_logo_white.png"}) {
      childrenImageSharp{
              gatsbyImageData(width: 280, placeholder: TRACED_SVG) 
      }
    }
  }`)
  
  const {gatsbyImageData} = data.logo.childrenImageSharp[0]
  const image = getImage(gatsbyImageData)

  return (
    <GatsbyImage image={image} sizes="(max-width: 280px) 280px" alt="logo"/>
  )

}

// Image.propTypes = {
//   filename: PropTypes.string.isRequired
// }

export default Logo