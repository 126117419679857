import React, { Component } from 'react'
import { gsap,Power1,CSSPlugin } from "gsap"

gsap.registerPlugin(CSSPlugin)

export default class BackgroundAnimation extends Component {

    tl

    componentDidMount() {
        this.tl = gsap.timeline();
        this.tl.from('#Star_1', {opacity: 0, ease: Power1.easeOut,scale:0, transformOrigin:"left top", filter: "blur(6px)"}, 0.02);
        this.tl.from('#Star_2', {opacity: 0, ease: Power1.easeOut,scale:0, transformOrigin:"left top", filter: "blur(6px)"}, 0.5);
        this.tl.from('#Star_3', {opacity: 0, ease: Power1.easeOut,scale:0, transformOrigin:"left top", filter: "blur(6px)"}, 0.2);
        this.tl.from('#Star_4', {opacity: 0, ease: Power1.easeOut,scale:0, transformOrigin:"left top", filter: "blur(6px)"}, 0.5);
        this.tl.from('#Star_5', {opacity: 0, ease: Power1.easeOut,scale:0, transformOrigin:"left top", filter: "blur(6px)"},  0.3);
        this.tl.from('#Star_6', {opacity: 0, ease: Power1.easeOut,scale:0, transformOrigin:"left top", filter: "blur(6px)"}, 0.4);
    }


    play() {
        this.tl.restart()
    }


    render() {
        return (
            <div className="svg-anim">
                <svg width="682.817" height="679.377" viewBox="0 0 682.817 679.377">
                    <defs>
                        <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                            <stop offset="0" stop-color="#fff" stop-opacity="0" />
                            <stop offset="1" stop-color="#a1a1a1" />
                        </linearGradient>
                    </defs>
                    <g id="Star" transform="translate(-2021.218 1992.145) rotate(-101)">
                        <path id="Star_6" d="M0,0H4A0,0,0,0,1,4,0V740a2,2,0,0,1-2,2H2a2,2,0,0,1-2-2V0A0,0,0,0,1,0,0Z" transform="translate(1278.119 2307.489) rotate(42)" opacity="0.3" fill="url(#linear-gradient)" />
                        <path id="Star_5" d="M0,0H4A0,0,0,0,1,4,0V740a2,2,0,0,1-2,2H2a2,2,0,0,1-2-2V0A0,0,0,0,1,0,0Z" transform="translate(1482.717 2392.836) rotate(42)" opacity="0.22" fill="url(#linear-gradient)" />
                        <path id="Star_4" d="M0,0H4A0,0,0,0,1,4,0V369a2,2,0,0,1-2,2H2a2,2,0,0,1-2-2V0A0,0,0,0,1,0,0Z" transform="translate(1273.791 2505.649) rotate(42)" opacity="0.22" fill="url(#linear-gradient)" />
                        <path id="Star_3" d="M0,0H4A0,0,0,0,1,4,0V369a2,2,0,0,1-2,2H2a2,2,0,0,1-2-2V0A0,0,0,0,1,0,0Z" transform="translate(1523.539 2526.424) rotate(42)" opacity="0.22" fill="url(#linear-gradient)" />
                        <path id="Star_2" d="M0,0H4A0,0,0,0,1,4,0V369a2,2,0,0,1-2,2H2a2,2,0,0,1-2-2V0A0,0,0,0,1,0,0Z" transform="translate(1398.703 2586.819) rotate(42)" opacity="0.22" fill="url(#linear-gradient)" />
                        <path id="Star_1" d="M0,0H4A0,0,0,0,1,4,0V369a2,2,0,0,1-2,2H2a2,2,0,0,1-2-2V0A0,0,0,0,1,0,0Z" transform="translate(1508.519 2661.892) rotate(42)" opacity="0.22" fill="url(#linear-gradient)" />
                    </g>
                </svg>
            </div>
        )
    }
}