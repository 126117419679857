import * as React from "react"
import {useState, useEffect} from "react"
import {Link, useTranslation, useI18next, Trans} from 'gatsby-plugin-react-i18next';
import Logo from "./logo"




const NavItem = ({children}) => {
    return(
        <><span className="text-transparent group-hover:text-icon_color-active"> &lt; </span> {children}<span className="text-transparent group-hover:text-icon_color-active"> &gt; </span></>
    )
}


const LangSpan = ({lOne, lTwo}) => {
    

    return(<React.Fragment>
                    <div className="group rounded-full bg-lang-btn-color pt-4 pb-4 pr-5 pl-5 hover:bg-lang-btn-color-hover text-icon_color-active w-14">
                            <span className={`group-hover:invisible w-5`}>{lOne}</span> 
                            <spam className={`relative -left-5 invisible group-hover:visible`}>{lTwo}</spam> 
                    </div>
            </React.Fragment>)
}


const Navigation = () => {  

    const {languages, originalPath, language} = useI18next()

    let isMainLang =   (language === languages[0])

    const { t } = useTranslation()

    const [ menuStatus, setMenuStatus ] = useState(false)

    const toggleMenu = () => {setMenuStatus(!menuStatus)}


    return (
        <nav class="container mx-auto pt-5">
            <div className="flex flex-row lg:w-4/5 mx-auto justify-between">

                <div className="w-3/6 p-2">
                    <Logo  />  
                </div> 
               
                <div className="hidden space-x-8 text-nav-color lg:flex pt-5">
                    <Link activeClassName="text-icon_color-active nav-hack" to="/"><NavItem > {t('navigation.home')} </NavItem></Link>
                    <Link activeClassName="text-icon_color-active nav-hack" to="/about"><NavItem> {t('navigation.about')} </NavItem></Link>
                    <Link activeClassName="text-icon_color-active nav-hack" to="/portfolio"><NavItem>{t('navigation.portfolio')}</NavItem></Link>
                    <Link activeClassName="text-icon_color-active nav-hack" to="/contact"><NavItem >{t('navigation.contact')}</NavItem></Link>
                    <div className="text-right -mt-3">
                        <Link hidden={!isMainLang}  to={originalPath} language={languages[1]} > 
                                <LangSpan lOne={languages[0].toUpperCase()} lTwo={languages[1].toUpperCase()} />
                        </Link>
                        <Link hidden={isMainLang} to={originalPath} language={languages[0]} > 
                            <LangSpan lOne={languages[1].toUpperCase()} lTwo={languages[0].toUpperCase()} />
                        </Link>
                    </div>
                </div>

                <div onClick={toggleMenu} class="h-fit flex m-2  mr-5 py-3 px-2 lg:hidden text-hover-color bg-icon_color-active rounded">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-6 h-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M4 6h16M4 12h16M4 18h16"
                        />
                    </svg>
                </div>

            </div>

            <div className={` ${menuStatus ? '' : 'hidden'} lg:hidden flex flex-col text-2xl space-y-2 pt-2 pl-2`} > 

                <Link activeClassName="text-icon_color-active nav-hack" to="/"><NavItem > {t('navigation.home')} </NavItem></Link>
                <Link activeClassName="text-icon_color-active nav-hack" to="/about"><NavItem> {t('navigation.about')} </NavItem></Link>
                <Link activeClassName="text-icon_color-active nav-hack" to="/portfolio"><NavItem>{t('navigation.portfolio')}</NavItem></Link>
                <Link activeClassName="text-icon_color-active nav-hack" to="/contact"><NavItem >{t('navigation.contact')}</NavItem></Link>

                <div className="pt-5 pb-6">
                    <Link hidden={!isMainLang} className="rounded-full bg-lang-btn-color pt-4 pb-4 pr-5 pl-5 hover:bg-lang-btn-color-hover text-icon_color-active" to={originalPath} language={languages[1]} >{languages[0].toUpperCase()}</Link>
                    <Link hidden={isMainLang} className="rounded-full bg-lang-btn-color pt-4 pb-4 pr-4 pl-5 hover:bg-lang-btn-color-hover text-icon_color-active" to={originalPath} language={languages[0]} >{languages[1].toUpperCase()}</Link>
                </div>
                
            </div>

            
        </nav>
)

}



export default Navigation


